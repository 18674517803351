.dropdown {
  margin-top: 10px;
}

.dropzone {
  width: 100%;
  padding: 3rem;
  border: 2px dashed #767676;
  background-color: rgba(117, 117, 117, 0.1);
  border-radius: 5px;
  text-align: center;
}

.dropzone :focus {
  outline: 0 !important;
}
