.paragraph {
  display: inline;
  padding: 10px;
  color: rgba(0, 0, 0, 0.6);
}

.icon {
  color: rgba(0, 0, 0, 0.6);
  margin: 10px;
}

.info {
  display: inline-block;
  width: 50%;
}

.dropdown {
  display: inline-block;
  width: 50%;
}
