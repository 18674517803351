.container {
  position: fixed;
  top: 16px;
  right: 16px;
  width: 250px;
  z-index: 9999;
}

.message {
  transition: 0.5s all ease-in-out;
}
